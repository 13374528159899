<template>
  <div ref="templateEditorDiv">
    <b-modal ref="warningDialog" title="Report Template Editor Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only >
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="addTemplateDialog" title="Add Report Template"
      header-bg-variant="primary" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" 
      ok-title="Add New Template" @ok="handleAddTemplateOK" :ok-disabled="(templateTenantNew=='' || templateNameNew=='') && (templateTypeNew != 'Import Templates') || (templateTypeNew == '') ">
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="tenantOptionsLabel">
              <b-form-select v-model="templateTenantNew" :options="tenantOptions"></b-form-select> 
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Create or Import Template(s):">
              <b-form-select v-model="templateTypeNew" :options="templateType"></b-form-select> 
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Template Name:" label-for="templateNameNew">
              <b-form-input id="templateNameNew" v-model="templateNameNew" :disabled ="templateTypeNew == 'Import Templates'" placeholder="" autofocus/>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal ref="selectTemplateDialog" title="Select Template" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow"
      ok-title="Edit Template" @ok="handleSelectOK" :ok-disabled="templateId==''"
      no-close-on-esc no-close-on-backdrop hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-form-group label="Template:" label-for="templateId">
              <b-form-input ref="templateFilterInput" v-model="templateFilter" placeholder="Filter for template names..." autofocus @update="templateId = ''"></b-form-input>
              <b-form-select id="templateId" v-model="templateId" :options="templateOptions" :select-size="selectSize">
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <div ref="navBar">
      <UserAdministrationNavBar />
    </div>
    <a ref="altReportLauncher" target="_blank"></a>
    <b-badge class="w-100 overflow-hidden" variant="info" show size="sm">
      {{ title }}
    </b-badge>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-button-group size="sm">
          <b-button @click="selectTemplate" variant="secondary" title="Select Template to Edit">
            <b-icon icon="journal-code"></b-icon>
          </b-button>
          <b-button class="ml-1" @click="newTemplate" variant="secondary" title="Add New Template">
            <b-icon icon="journal-plus"></b-icon>
          </b-button>
        </b-button-group>
        <span v-if="loadInProgress" class="ml-2 text-white" >
          <b-spinner label="Loading..." variant="info"/> Loading template...
        </span>
        <span v-if="saveInProgress" class="ml-2 text-white" >
          <b-spinner label="Saving templates..." variant="info"/> Saving template...
        </span>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-dropdown size="sm" title="Insert placeholder" right :disabled="reportBuffer==''">
          <template #button-content>
            <b-icon icon="braces"></b-icon>
          </template>
          <b-dropdown-item v-for="ph in placeholders" :key="'ph_'+ph.id" @click="insertPlaceholder(ph.id)">
            <b-icon icon="braces"/> {{ph.name}}
          </b-dropdown-item>
        </b-dropdown>
        <b-nav-text class="fixedHeight">&nbsp;</b-nav-text>
        <b-button-group size="sm">
          <b-button @click="handleDownload()" variant="secondary" title="Download Template (DOCX)" :disabled="reportBuffer == ''">
            <b-icon icon="file-word"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleSave" title="Save Template" :disabled="!dirty || saveInProgress">
            <b-icon icon="journal-check"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleClose" title="Close Template">
            <b-icon icon="journal-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <b-alert v-if="reportError" class="mt-2" variant="warning" show>
      Editable template not ready.
      <b-button class="ml-2" variant="primary" @click="show()">Retry</b-button>
    </b-alert>
    <div v-show="reportBuffer==''" class="text-white text-center">
      Create master template.
    </div>
    
    <div v-show="reportBuffer!=''" id="doceditdiv" ref="doceditdiv" :width="editorWidth">
      <div>
        <input type="file" ref="fileUpload" v-on:change="onFileUpload" accept=".doc,.docx," style="position:fixed; left:-100em" multiple/>
        <ejs-toolbar v-on:clicked="toolbarButtonClick">
          <e-items> 
            <e-item prefixIcon="e-de-ctnr-bold e-icons" tooltipText="Bold" id="bold"></e-item>
            <e-item prefixIcon="e-de-ctnr-italic e-icons" tooltipText="Italic" id="italic"></e-item>
            <e-item prefixIcon="e-de-ctnr-underline e-icons" tooltipText="Underline" id="underline"></e-item>
            <e-item prefixIcon="e-de-ctnr-strikethrough e-icons" tooltipText="Strikethrough" id="strikethrough"></e-item>
            <e-item prefixIcon="e-de-ctnr-subscript e-icons" tooltipText="Subscript" id="subscript"></e-item>
            <e-item prefixIcon="e-de-ctnr-superscript e-icons" tooltipText="Superscript" id="superscript"></e-item>
            <e-item type="Seperator"></e-item>  
            <e-item type="Input" template="fontColorTemplate"></e-item>
            <e-item type="Seperator"></e-item>
            <e-item type="Input" template="fontFamilyTemplate"></e-item>
            <e-item type="Input" template="fontSizeTemplate"></e-item>
            <e-item type="Seperator"></e-item>  
            <e-item type="Seperator"></e-item> 
            <e-item prefixIcon='e-de-ctnr-alignleft e-icons' id='AlignLeft' tooltipText='Align Left'></e-item>
            <e-item prefixIcon='e-de-ctnr-aligncenter e-icons' id='AlignCenter' tooltipText='Align Center'></e-item>
            <e-item prefixIcon='e-de-ctnr-alignright e-icons' id='AlignRight' tooltipText='Align Right'></e-item>  
            <e-item prefixIcon='e-de-ctnr-justify e-icons' id='Justify' tooltipText='Justify'></e-item>
            <e-item prefixIcon='e-de-ctnr-increaseindent e-icons' id='IncreaseIndent' tooltipText='Increase Indent'></e-item>
            <e-item prefixIcon='e-de-ctnr-decreaseindent e-icons' id='DecreaseIndent' tooltipText='Decrease Indent'></e-item>
            <e-item type="Input" template="lineSpacingTemplate"></e-item>
            <e-item type="Input" template="bulletTemplate"></e-item>
            <e-item type="Input" template="numberingTemplate"></e-item>
            <e-item type='Separator'></e-item>
            <e-item prefixIcon='e-de-ctnr-clearall e-icons' id='ClearFormat' tooltipText='ClearFormatting'></e-item>
            <e-item type='Separator'></e-item>
            <e-item prefixIcon='e-de-e-paragraph-mark e-icons' id='ShowParagraphMark'
              tooltipText='Show the hidden characters like spaces, tab, paragraph marks, and breaks.(Ctrl + *)'></e-item>
            <e-item prefixIcon ='e-de-ctnr-upload e-icons' id='importnewtemplates' tooltipText='Import New Templates from Local'></e-item>
            <e-item type="Seperator"></e-item>  
            <e-item type="Seperator"></e-item>
            <e-item prefixIcon ='e-de-ctnr-lock e-icons' id='showhidetoolbar' tooltipText='Show/Hide Toolbar'></e-item>  
          </e-items>
          <template v-slot:fontColorTemplate>
            <ejs-colorpicker value='#000000' :showButtons='true' v-bind:change='onFontColorChange'> </ejs-colorpicker>
          </template>
          <template v-slot:fontFamilyTemplate>
            <ejs-combobox :dataSource='fontStyle' :width='150' :index='2' :allowCustom='true'
              v-bind:change='onFontFamilyChange' :showClearButton='false'> </ejs-combobox>
          </template>
          <template v-slot:fontSizeTemplate>
            <ejs-combobox :dataSource='fontSize' :width='80' :index='2' :allowCustom='true'
              v-bind:change='onFontSizeChange' :showClearButton='false'> </ejs-combobox>
          </template>
          <template v-slot:lineSpacingTemplate>
            <ejs-combobox :dataSource='lineSpacing' :width='80' :index='2' :allowCustom='true'
              v-bind:change='onLineSpacingChange' :showClearButton='false'> </ejs-combobox>
          </template>
          <template v-slot:bulletTemplate>
            <ejs-dropdownbutton v-bind:select='bulletButtonClick' :items='bullet' :with="40" iconCss='e-de-ctnr-bullets e-icons' cssClass='e-caret-hide'></ejs-dropdownbutton>
          </template>
          <template v-slot:numberingTemplate>
            <ejs-dropdownbutton v-bind:select='numberingButtonClick' :items='numbering' :with="40" iconCss='e-de-ctnr-numbering e-icons' cssClass='e-caret-hide'></ejs-dropdownbutton>
          </template> 
        </ejs-toolbar>
      </div>
      <ejs-documenteditorcontainer ref="doceditcontainer" v-bind:selectionChange='onSelectionChange' :toolbarItems='items' 
        :height="editorHeight" 
        :serviceUrl='serviceUrl'
        :documentEditorSettings="fontStyle"
        :enableLocalPaste='false'
        :enableToolbar='false'
        :enableSpellCheck='true'
        :enableSfdtExport='true'
        :enableWordExport='true'
        :enableTextExport='true'
        :enableEditorHistory='true'
        :enableEditor='true'
        :isReadOnly='false'
        :showPropertiesPane='false'>
      </ejs-documenteditorcontainer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import permissions from '../common/permissions';
import webServices from '../common/webServices'
import UserAdministrationNavBar from "./UserAdministrationNavBar.vue";

// SyncFusion Document Editor
// https://ej2.syncfusion.com/vue/documentation/document-editor/getting-started/#run-the-documenteditor-application
//
// Context Menu
import { ContextMenu } from '@syncfusion/ej2-navigations';
import { Browser } from '@syncfusion/ej2-base';

import { DocumentEditorContainerComponent as EjsDocumenteditorcontainer, Toolbar, Editor, Selection, EditorHistory } from '@syncfusion/ej2-vue-documenteditor';
import { ItemDirective as EItem, ItemsDirective as EItems, ToolbarComponent as EjsToolbar } from "@syncfusion/ej2-vue-navigations";
import { ColorPickerComponent as EjsColorpicker } from '@syncfusion/ej2-vue-inputs';
import { ComboBoxComponent as EjsCombobox } from "@syncfusion/ej2-vue-dropdowns";
import { DropDownButtonComponent as EjsDropdownbutton } from "@syncfusion/ej2-vue-splitbuttons";
import { ref } from 'vue';

export default {
  name: 'reportTemplateEditor',
  components: {
    UserAdministrationNavBar,
    "ejs-documenteditorcontainer": EjsDocumenteditorcontainer, EItem, EItems, EjsToolbar, EjsColorpicker, EjsCombobox, EjsDropdownbutton
  },
  data() {
    return {
      chunks: [],
      dirty: false,
      documentEditorSettings: {
        fontFamilies: this.$store.state.reportFonts
      },
      editorEnableToolbar: false,
      editorHeight: '200px',
      editorWidth: '100px',
      contextMenuObj: null,
      phraseTracker: null,
      placeholders: [
        { id: "AccessionNumber", name: "Accession Number" },
        { id: "DateOfBirth", name: "Date of Birth" },
        { id: "Gender", name: "Gender" },
        { id: "Modality", name: "Modality" },
        { id: "PatientAddress", name: "Patient Address" },
        { id: "PatientAge", name: "Patient Age" },
        { id: "PatientEmail", name: "Patient Email" },
        { id: "PatientId", name: "Patient Id (MRN)" },
        { id: "PatientName", name: "Patient Name" },
        { id: "PatientTelephone", name: "Patient Telephone" },
        { id: "ReferredBy", name: "Referring Physician" },
        { id: "ReportDateTime", name: "Report Date/Time" },
        { id: "SignaturePlaceholder", name: "Signature" },
        { id: "StudyDateTime", name: "Study Date/Time" },
        { id: "StudyDescription", name: "Study Description" }
      ],
      psuedoTenantForUser: '__U_s_E_r__',
      reportBuffer: '',
      reportError: false,
      retryAddTemplate: false,
      loadInProgress: false,
      saveInProgress: false,
      templateListTenants: {},
      templateListUser: [],
      templateOptions: [],
      templateTenant: '',
      templateName: '',
      templateId: '',
      templateFilter: '',
      templateTenantNew: '',
      templateNameNew: '',
      templateTypeNew: '',
      templateReport: '',
      tenants: {},
      warningMsg: '',
      templateFiles: [],
      templateCount: 0,
      filePicker: ref(null),
      doceditcontainer: ref(null),
      fontStyle: this.$store.state.reportFonts,
      fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '36', '48', '72', '96'],
      lineSpacing: ['Single', '1.15', '1.5', 'Double'],
      bullet: [
        {
          iconCss: 'e-de-ctnr-bullet-none e-icons e-de-ctnr-list', text: 'None', id : '1'
        },
        {
          iconCss: 'e-de-ctnr-bullet-dot e-icons e-de-ctnr-list', text: 'Dot', id : '2'
        },
        {
          iconCss: 'e-de-ctnr-bullet-circle e-icons e-de-ctnr-list', text: 'Circle', id : '3'
        },
        {
          iconCss: 'e-de-ctnr-bullet-square e-icons e-de-ctnr-list', text: 'Square', id : '4'
        },
        {
          iconCss: 'e-de-ctnr-bullet-flower e-icons e-de-ctnr-list', text: 'Flower', id : '5'
        },
        {
          iconCss: 'e-de-ctnr-bullet-arrow e-icons e-de-ctnr-list', text: 'Arrow', id : '6'
        },
        {
          iconCss: 'e-de-ctnr-bullet-tick e-icons e-de-ctnr-list', text: 'Tick', id : '7'
        },
      ],

    numbering: [
      {
        text: 'None', id : '1'
      },
      {
        text: '1.___', id : '2'
      },
      {
        text: 'a.___', id : '3'
      },
      {
      text: 'A.___', id : '4'
      },
      {
        text: 'i.___', id : '5'
      },
      {
        text: 'I.___', id : '6'
      }
    ],
   
    items: ['Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'Separator', 'LocalClipboard', 'RestrictEditing', 'Separator', 'FormFields', 'UpdateFields','ContentControl'],
    };
  },
  provide: {
    DocumentEditorContainer: [Toolbar, Editor, Selection, EditorHistory]
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    isSuperAdmin() {
      return permissions.isSuperAdmin()
    },
    activeComponent() {
      return this.$store.state.activeComponent
    },
    title() {
      var title = "Master Template Editor"
      if (this.templateTenant != '') {
        if (this.templateTenant == this.psuedoTenantForUser) {
          title += ` : ${this.user}`
        }
        else {
          title += ` : ${this.templateTenant}`
        }
      }
      if (this.templateName != '') {
        title += ` : ${this.templateName}`
      }
      return title
    },
    serviceUrl() {
      this.$log.debug('serviceUrl='+this.$store.state.docServicesUrl)
      return this.$store.state.docServicesUrl
    },
    user() {
      return this.$store.getters.user || 'User Name Not Set'
    },
    selectSize() {
      return ((this.templateId !== '') || (this.templateFilter === '')) ? 0 : 10
    },
    canEditTenantTemplates() {
      return (permissions.isSuperAdmin() || (permissions.customersForPermission(permissions.CAN_EDIT_REPORT_TEMPLATES).length > 0))
    },
    canEditUserTemplates() {
      return (permissions.groupsForPermission(permissions.CAN_USE_TEMPLATE_EDITOR).length > 0)
    },
   customerNames() {
      // Return sorted list of customer names available for template creation.
      //
      var customerNames = []
      Object.keys(this.tenants).forEach(customerId => {
        customerNames.push(this.tenants[customerId].name)
      })
      customerNames.sort()
      return customerNames
    },
    templateType() {
      var templateType = [];
      if (this.canEditUserTemplates || this.canEditTenantTemplates) {
        templateType.push("Create New Template")
        templateType.push("Import Templates")
      }
      return templateType;
    },
    tenantOptionsLabel() {
      var label = "Select User for Template:"
      if (this.canEditTenantTemplates && this.canEditUserTemplates) {
        label = "Select User or Tenant for Template:"
      }
      else if (this.canEditTenantTemplates) {
        label = "Select Tenant for Template:"
      }
      return label
    },
    tenantOptions() {
      var tenantOptions = [{ value: '', text: "Select..."}]
      if (this.canEditUserTemplates) {
        tenantOptions.push({ value: this.psuedoTenantForUser, text: `User (${this.user})`})
      }
      try {
        this.customerNames.forEach(customerName => {
          let tenantsUnderCustomerOptions = []
          Object.keys(this.tenants).forEach(customerId => {
            if (this.tenants[customerId].name === customerName) {
              let customerItem = this.tenants[customerId]
              Object.keys(customerItem.tenants).sort().forEach(tenantId => {
                let templateTenant = (customerId == permissions.PSUEDO_CUSTOMER_ID) ? customerItem.tenants[tenantId] : `${customerName}/${customerItem.tenants[tenantId]}`
                tenantsUnderCustomerOptions.push({ value: templateTenant, text: customerItem.tenants[tenantId]})
              })
              tenantOptions.push({ label: customerName, options: tenantsUnderCustomerOptions})
            }
          })
        })
      }
      catch(err) {
        this.$log.error(`Error parsing all customers: ${err.message}`)
      }
      return tenantOptions
    },
  },
  watch: {
    templateFilter(/*newVal, oldVal*/) {
      this.generateTemplateOptions()
    }
  },
  mounted() {
    this.$nextTick(function () {
      var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor;
      
      var menuItems = [ {
        text: 'Insert Place Holders',
        id: 'InsertPlaceholedrs',
        items: [
          { text: 'Accession Number', id: 'AccessionNumber', },
          { text: 'Date of Birth', id: 'DateOfBirth', },
          { text: 'Gender', id: 'Gender', },
          { text: 'Modality', id: 'Modality', },
          { text: 'Patient Address', id: 'PatientAddress', },
          { text: 'Patient Age', id: 'PatientAge', },
          { text: 'Patient Email', id: 'PatientEmail', },
          { text: 'Patient Id (MRN)', id: 'PatientId', },
          { text: 'Patient Name', id: 'PatientName', },
          { text: 'Patient Telephone', id: 'PatientTelephone', },
          { text: 'Referring Physician', id: 'ReferredBy', },
          { text: 'Report Date/Time', id: 'ReportDateTime', },
          { text: 'Signature', id: 'SignaturePlaceholder', },
          { text: 'Study Date/Time', id: 'StudyDateTime', },
          { text: 'Study Description', id: 'StudyDescription', }
        ]
      }];
      // adding Custom Options
      obj.contextMenu.addCustomMenu(menuItems, false);
      // custom Options Select Event
      obj.customContextMenuSelect = (args) => {
          this.handleCustomMenuSelect(args);
      };
      //  custom options hide/show functionality
      obj.customContextMenuBeforeOpen = (args) => {
          this.handleMenuCustomization(args);
      }
    });
  },
  methods: {
    openFileButtonClickHandler() {
      this.$refs.fileUpload.click();
    },
    onFileUpload(e)  {
      if (e.target.files) {
        this.templateFiles = []
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i]
          this.templateFiles.push(file)
        }
        if (this.templateFiles.length == 1) {
          let file = this.templateFiles[0];
          this.loadFile(file)
          var fpath = file.name.replace(/\\/g, '/');
          var fileName = fpath.substring(fpath.lastIndexOf('/')+1, fpath.lastIndexOf('.'));
          this.templateName = fileName
        }
        else {
          this.displayToast(this.templateFiles.length + " Report templates are imported.","info")
        }
      } 
    },
    loadFile(file) {
      let ajax = new XMLHttpRequest();
      ajax.open('POST', `${this.serviceUrl}/import`, true);
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4) {
          if (ajax.status === 200 || ajax.status === 304) {
            this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.paste(ajax.responseText);
          }
        }
      }
      let formData = new FormData();
      formData.append('files', file);
      ajax.send(formData);
    },
    handleCustomMenuSelect(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertText("«" + args.id + "»");//«»
    },
    handleMenuCustomization: function (args) {
        var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor;
        let search = document.getElementById(args.ids[0]);
        search.style.display = 'block';
        let searchContent = documentEditor.selection.text;
        if (!documentEditor.selection.isEmpty && /\S/.test(searchContent)) {
            //search.style.display = 'block';
        }
    },
    toolbarButtonClick(arg) {
      switch (arg.item.id) {
        case 'bold':
          //Toggles the bold of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleBold();
          break;
        case 'italic':
          //Toggles the Italic of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleItalic();
          break;
        case 'underline':
          //Toggles the underline of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleUnderline('Single');
          break;
        case 'strikethrough':
          //Toggles the strikethrough of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleStrikethrough();
          break;
        case 'subscript':
          //Toggles the subscript of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleSubscript();
          break;
        case 'superscript':
          //Toggles the superscript of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleSuperscript();
          break;
        case 'AlignLeft':
          //Toggle the Left alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Left');
          break;
        case 'AlignRight':
          //Toggle the Right alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Right');
          break;
        case 'AlignCenter':
          //Toggle the Center alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Center');
          break;
        case 'Justify':
          //Toggle the Justify alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Justify');
          break;
        case 'IncreaseIndent':
          //Increase the left indent of selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.increaseIndent();
          break;
        case 'DecreaseIndent':
          //Decrease the left indent of selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.decreaseIndent();
          break;
        case 'ClearFormat':
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.clearFormatting();
          break;
        case 'ShowParagraphMark':
          //Show or hide the hidden characters like spaces, tab, paragraph marks, and breaks.
          this.$refs.doceditcontainer.ej2Instances.documentEditor.documentEditorSettings.showHiddenMarks = !this.$refs.doceditcontainer.ej2Instances.documentEditor.documentEditorSettings.showHiddenMarks;
          break;
        case 'showhidetoolbar':
          this.$refs.doceditcontainer.ej2Instances.enableToolbar = !this.$refs.doceditcontainer.ej2Instances.enableToolbar
          break;
        case 'importnewtemplates':
          this.openFileButtonClickHandler();
          break;
      }
    },
    numberingButtonClick(args) {
      switch (args.item.id) {
        case '1':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.clearList();
          break;
        case '2':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'Arabic');
          break;
        case '3':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'LowLetter');
          break;
        case '4':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'UpLetter');
          break;
        case '5':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'LowRoman');
          break;
        case '6':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'UpRoman');
          break;
      }
    },
    bulletButtonClick(args) {
      switch (args.item.id) {
        case '1':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.clearList();
          break;
        case '2':
          //To create bullet list
          //this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61623), 'Symbol');
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet('●', 'Symbol');
          break;
        case '3':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61551) + String.fromCharCode(32), 'Symbol');
          break;
        case '4':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61607), 'Wingdings');
          break;
        case '5':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61558), 'Wingdings');
          break;
        case '6':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61656), 'Wingdings');
          break;
        case '7':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61692), 'Wingdings');
          break;
      }
    },
    onFontFamilyChange(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat.fontFamily = args.value;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onFontSizeChange(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat.fontSize = args.value;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onFontColorChange(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat.fontColor = args.currentValue.hex;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onLineSpacingChange(args) {
      var value;
      switch (args.value) {
          case 'Single':
              value = 1;
              break;
          case '1.15':
              value = 1.15;
              break;
          case '1.5':
              value = 1.5;
              break;
          case 'Double':
              value = 2;
              break;
      }
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.paragraphFormat.lineSpacing = value;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onSelectionChange() {
      var characterformat = this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat;
      var properties = [characterformat.bold, characterformat.italic, characterformat.underline, characterformat.strikeThrough];
      var toggleBtnId = ["bold", "italic", "underline", "strikethrough"];
      // this.$refs.fontFamilyTemplate.value = characterformat.fontFamily;
      for (var i = 0; i < properties.length; i++) {
        let toggleBtn = document.getElementById(toggleBtnId[i]);
        if ((typeof (properties[i]) == 'boolean' && properties[i] == true) || (typeof (properties[i]) == 'string' && properties[i] !== 'None'))
          toggleBtn.classList.add("e-btn-toggle");
        else {
          if (toggleBtn.classList.contains("e-btn-toggle"))
            toggleBtn.classList.remove("e-btn-toggle");
        }
      }
      if (this.$refs.doceditcontainer.ej2Instances.documentEditor.selection) {
        var paragraphFormat = this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.paragraphFormat;
        toggleBtnId = ['AlignLeft', 'AlignCenter', 'AlignRight', 'Justify', 'ShowParagraphMark'];
        for (var j = 0; j < toggleBtnId.length; j++) {
          let toggleBtn = document.getElementById(toggleBtnId[j]);
          //Remove toggle state.
          toggleBtn.classList.remove('e-btn-toggle');
        }
        //Add toggle state based on selection paragraph format.
        if (paragraphFormat.textAlignment === 'Left') {
          document.getElementById('AlignLeft').classList.add('e-btn-toggle');
        } else if (paragraphFormat.textAlignment === 'Right') {
          document.getElementById('AlignRight').classList.add('e-btn-toggle');
        } else if (paragraphFormat.textAlignment === 'Center') {
          document.getElementById('AlignCenter').classList.add('e-btn-toggle');
        } else {
          document.getElementById('Justify').classList.add('e-btn-toggle');
        }
        if (this.$refs.doceditcontainer.ej2Instances.documentEditor.documentEditorSettings.showHiddenMarks) {
          document.getElementById('ShowParagraphMark').classList.add('e-btn-toggle');
        }
      }
    },    
    initEditor(openBlank) {
      this.documentEditorSettings = {
        fontFamilies: this.$store.state.reportFonts
      }
      var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
      obj.enableContextMenu = true // replaced default with one above to handle paste from system clipboard
      obj.addEventListener("contentChange", this.handleContentChange)
      obj.addEventListener("keyDown", this.handleKeyDown)
      obj.addEventListener("selectionChange", this.handleSelectionChange)
      //obj.insertTable(1,4)
      // Configure spell checker: https://ej2.syncfusion.com/javascript/documentation/document-editor/spell-check/
      // Server-side: https://hub.docker.com/r/syncfusion/word-processor-server
      //
      //obj.spellChecker.languageID = 1033; // LCID of "en-US"
      //obj.spellChecker.removeUnderline = false;
      //obj.spellChecker.allowSpellCheckAndSuggestion = true;
      //obj.spellChecker.enableOptimizedSpellCheck = true;

      if (openBlank) {
        obj.openBlank()
      }
      return obj
    },
    show() {
      if (this.contextMenuObj == null) {
        // +TODO+ Add icons with iconCss propertie for each menu item.
        // +TODO+ Handle Paste Special correctly (CTRL-v/CMD-v works with formatting, images).
        //
        let menuItems = [
          {
            text: 'Copy'
          },
          {
            text: 'Cut',
          },
          {
            text: 'Paste Text',
          },
          //{
          //  text: 'Paste Special',
          //},
          {
              separator: true
          },
          {
            text: 'Hyperlink…',
          },
          {
            text: 'Font…',
          },
          {
            text: 'Paragraph…',
          }
        ];

        //ContextMenu model definition
        let menuOptions = {
            target: '#doceditdiv',
            items: menuItems,
            // Event triggers while rendering each menu item where some menu item(s) may be disabled
            //
            //beforeItemRender: (args) => {
            //  if (args.item.text === 'Paste Special') {
            //    args.element.classList.add('e-disabled');
            //  }
            //}
        };

        this.contextMenuObj = new ContextMenu(menuOptions, '#contextmenu');
        this.contextMenuObj.animationSettings.effect = (Browser.isDevice) ? 'ZoomIn' : 'SlideDown';
        this.contextMenuObj.addEventListener('select', this.handleContextMenu)
      }

      this.dirty = false
      this.templateName = '',
      this.templateTenant = '',
      this.templateOptions = [],
      this.reportBuffer = ''
      this.tenantLut = {}
      this.customerLut = {}
      this.templateListTenants = {}
      this.templateListUser = []
      this.reportError = false
      this.loadInProgress = false
      this.saveInProgress = false
      this.editorEnableToolbar = false
      this.retryAddTemplate = false
      webServices.readAdminTenants()
      .then(response => {
        this.tenants = response
      })
      .catch(err => {
        this.$log.error("Error fetching tenant list: "+err)
      })
      .finally(() => {
        this.$log.debug("getting template list")
        webServices.readReportTemplateList()
        .then(response => {
          if ((response != null) && response['templateList'] && response['templateList']['user']) {
            response['templateList']['user'].forEach((t) => {
              this.templateListUser.push(t.name);
            })
            this.templateListUser.sort();
          }
          else {
            this.$log.debug("Report template list for user is null or empty.")
          }
          if ((response != null) && response['templateList'] && response['templateList']['tenants']) {
            response['templateList']['tenants'].forEach((t) => {
              // Report code is using old-fashioned group name for tenant identifiers, so need to generate from 
              // customer and tenant names.
              //
              let templateTenant = 'TBD'
              Object.keys(this.tenants).forEach(customerId => {
                if (customerId === t.customer_id) {
                  let customerItem = this.tenants[customerId]
                  Object.keys(customerItem.tenants).forEach(tenantId => {
                    if (tenantId == t.tenant_id) {
                      templateTenant = (customerId == permissions.PSUEDO_CUSTOMER_ID) ? customerItem.tenants[tenantId] : `${customerItem.name}/${customerItem.tenants[tenantId]}`
                    }
                  })
                }
              })
              if (this.templateListTenants[templateTenant] === undefined) {
                this.templateListTenants[templateTenant] = []
              }
              this.templateListTenants[templateTenant].push(t.name);
              this.templateListTenants[templateTenant].sort();
            })
          }
          else {
            this.$log.debug(`Report template list for tenants is null or empty.`)
          }
        })
        .catch(err => {
          this.$log.error("Error fetching report template list: "+err)
        })
      })

      this.handleResize()
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'INSPIRE PACS',
        variant: variant,
      })
    },
    generateTemplateOptions() {
      this.templateOptions = [
        { value: '', text: 'Please select a template...', disabled: true }
      ]

      if (this.canEditUserTemplates) {
        var userOptions = {
          label: 'My Templates',
          options: []
        }

        var filteredUser = this.templateListUser.filter(item => {
          return item.match(new RegExp(this.templateFilter, "i"))
        })
        if (filteredUser.length == 0) {
          userOptions['options'].push({ value: '', text: 'No user templates', disabled: true })
        }
        for (var i=0; i < filteredUser.length; i++) {
          let templateName = filteredUser[i]
          let templateId = this.psuedoTenantForUser + '|' + templateName
          userOptions['options'].push({ value: ''+templateId, text: templateName })
        }
        this.templateOptions.push(userOptions)
      }

      if (this.canEditTenantTemplates) {
        try {
          this.customerNames.forEach(customerName => {
            Object.keys(this.tenants).forEach(customerId => {
              if (this.tenants[customerId].name === customerName) {
                let customerItem = this.tenants[customerId]
                Object.keys(customerItem.tenants).sort().forEach(tenantId => {
                  let templateTenant = (customerId == permissions.PSUEDO_CUSTOMER_ID) ? customerItem.tenants[tenantId] : `${customerName}/${customerItem.tenants[tenantId]}`
                  let tenantOptions = []
                  let filteredTenant = []
                  this.$log.debug(`templateTenant=${templateTenant}`)
                  this.$log.debug(this.templateListTenants[templateTenant])
                  if (this.templateListTenants[templateTenant] && Array.isArray(this.templateListTenants[templateTenant])) {
                    filteredTenant = this.templateListTenants[templateTenant].filter(item => {
                      return item.match(new RegExp(this.templateFilter, "i"))
                    })
                  }
                  if (filteredTenant.length == 0) {
                    tenantOptions.push({ value: '', text: 'No tenant templates', disabled: true })
                  }
                  for (i=0; i < filteredTenant.length; i++) {
                    // Template tenant must match Keycloak group for report template REST API
                    let templateName = filteredTenant[i]
                    tenantOptions.push({ value: `${templateTenant}|${templateName}`, text: templateName })
                  }
                  const label = this.isSuperAdmin ? `${customerName}/${customerItem.tenants[tenantId]}` : customerItem.tenants[tenantId]
                  this.templateOptions.push({ label: label, options: tenantOptions })
                })
              }
            })
          })
        }
        catch(err) {
          this.$log.error(`Error parsing all customers: ${err.message}`)
        }
      }
    },
    selectTemplate() {
      this.templateId = ''
      this.generateTemplateOptions()
      this.$refs['selectTemplateDialog'].show()
    },
    newTemplate() {
      var answer = true
      if (this.dirty) {
        answer = window.confirm('Do you really want to leave with unsaved changes?')
      }
      if (answer) {
        this.templateNameNew = ''
        this.templateTenantNew = (this.canEditUserTemplates) ? this.psuedoTenantForUser : ''
        this.templateTypeNew = (this.canEditUserTemplates) ? this.psuedoTenantForUser : ''
        this.$refs['addTemplateDialog'].show()
      }
    },
    handleAddTemplateOK() {
      // Verify name is unique and contains only valid chars.
      //
      //this.templateFiles = [];
      let unique = true
      if (this.templateTenantNew == this.psuedoTenantForUser) {
        this.templateListUser.forEach((t) => {
          if (t == this.templateNameNew) {
            unique = false
          }
        })
      }
      else if (this.templateListTenants[this.templateTenantNew]) {
        this.templateListTenants[this.templateTenantNew].forEach((t) => {
          if (t == this.templateNameNew) {
            unique = false
          }
        })
      }
      const regExp = /^[^\\/:*?"<>|]+$/
      const valid = regExp.test(this.templateNameNew)
      if (!unique) {
        this.warningMsg = `Template name [${this.templateNameNew}] is already be used.<br/><br/>Please try again using a unique name.`
        this.retryAddTemplate = true
        this.$refs['warningDialog'].show()
      }
      else if (!valid && this.templateTypeNew != "Import Templates") {
        this.warningMsg = `Template name [${this.templateNameNew}] contains reserved characters.<br/><br/>Please try again using only alphanumeric characters and spaces.`
        this.retryAddTemplate = true
        this.$refs['warningDialog'].show()
      }
      else {
        this.templateName = this.templateNameNew.trim()
        this.templateTenant = this.templateTenantNew
        this.templateReport = this.templateTypeNew
        
        this.reportBuffer = '__NEW_TEMPLATE__'
        this.dirty = true
        this.initEditor(true)
        this.handleResize()
        if ( this.templateTypeNew != "Import Templates") {
          document.getElementById("importnewtemplates").disabled = true;
        }
        else {
          document.getElementById("importnewtemplates").disabled = false;
        }
        //this.loadTemplate(this.templateTenant,"default")
      }
    },
    loadTemplate(tenant, name) {
      alert(tenant)
      alert(name)
      let format = 'sfdt'
      this.loadInProgress = true
      this.$log.debug(`Reading template [${tenant}|${name}]`)
      if (tenant == this.psuedoTenantForUser) {
        webServices.readReportTemplateForUser(name, format)
        .then(response => {
          if (response != null) { 
            var dataView = new DataView(response);
            var decoder = new TextDecoder('utf-8');
            this.reportBuffer = decoder.decode(dataView)
            var obj = this.initEditor(false)
            obj.open(this.reportBuffer)
            obj.documentName = name
            this.templateName = name
            this.templateTenant = tenant
            this.handleResize()
          }
          else {
            this.$log.debug("Report null or empty.")
            this.reportError = true
          }
          this.loadInProgress = false
        })
        .catch(err => {
          this.$log.warn("Error fetching report template, err: "+err.message)
          this.reportError = true
          this.loadInProgress = false
        })
      }
      else {
        webServices.readReportTemplateForGroup(name, `/${tenant}`, format)
        .then(response => {
          if (response != null) { 
            var dataView = new DataView(response);
            var decoder = new TextDecoder('utf-8');
            this.reportBuffer = decoder.decode(dataView)
            var obj = this.initEditor(false)
            obj.open(this.reportBuffer)
            obj.documentName = name
            this.templateName = name
            this.templateTenant = tenant
            this.handleResize()
          }
          else {
            this.$log.debug("Report null or empty.")
            this.reportError = true
          }
          this.loadInProgress = false
        })
        .catch(err => {
          this.$log.warn("Error fetching report template, err: "+err.message)
          this.reportError = true
          this.loadInProgress = false
        })
      }
    },
    insertPlaceholder(id) {
      const text = `«${id}»`
      var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
      documentEditor.editor.insertText(text)
    },
    handleClose() {
      var answer = true
      if (this.dirty) {
        answer = window.confirm('Do you really want to leave with unsaved changes?')
      }
      if (answer) {
        this.$store.commit('changeActiveStudyUid', '')
        this.$store.commit('changeActiveComponent', '')
      }
    },
    handleContentChange() {
      this.$log.debug(`ReportTemplateEditor contentChange event, dirty=${this.dirty}`)
      this.dirty = true
    },
    convertHtmlToSfdt(html) {
      // Remove nasty Microsoft elements from clipboard HTML if copied from Word.
      //
      this.$log.debug(html)
      const htmlClean = html.replace(/<o:p><\/o:p>/g, '')
      const xdoc = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'html', null)
      const body = document.createElementNS('http://www.w3.org/1999/xhtml', 'body')
      body.innerHTML = htmlClean
      xdoc.documentElement.appendChild(body);
      const xdocAsHtml = xdoc.documentElement.outerHTML
      this.$log.debug(xdocAsHtml)

      this.$log.debug("converting html to sfdt using Syncfusion container")
      const formData = new FormData();
      formData.append('files', new Blob([xdocAsHtml], {type: "text/html"}), 'clipboard.html');
      
      var docServicesImportUrl = `${this.serviceUrl}/import`
      axios.post(
        docServicesImportUrl, 
        formData,
        {
            headers: {
                //'Authorization': 'Bearer '+store.state.keycloak.token,
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
        this.$log.debug('received doc_services response')
        var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
        documentEditor.editor.paste(JSON.stringify(response.data))
      })
      .catch(err => {
        this.$log.error("doc_services error: "+err.message)
        const toastMsg = "Unable to convert clipboard contents, try using keyboard paste (CNTL-v/CMD-V)."
        this.displayToast(toastMsg, 'warning')
      })
    },
    handlePasteSpecial() {
      navigator.clipboard.read()
      .then(clipboardItems => {
        this.$log.debug(clipboardItems)
        for (const clipboardItem of clipboardItems) {
          if (clipboardItem.types.includes('text/html')) {
            for (const type of clipboardItem.types) {
              clipboardItem.getType(type)
              .then(blob => {
                if (type == 'text/html') {
                  blob.text()
                  .then(blobAsText => {
                    this.convertHtmlToSfdt(blobAsText)
                  })
                  .catch(bErr => {
                    this.$log.error(`Error getting text from clipboard item blob: ${bErr.message}`)
                  })
                }
              })
              .catch(ciErr => {
                this.$log.debug(`Error getting blob from clipboard item: ${ciErr.message}`)
              })
            }
          }
          else if (clipboardItem.types.includes('text/plain')) {
            // No HTML available in clibboard item, fall back to plain text.
            //
            this.handlePasteText()
          }
        }
      })
      .catch(err => {
        this.$log.debug(`Error copying from clipboard: ${err.message}`)
      })
    },
    handlePasteText() {
      navigator.clipboard.readText()
      .then(text => {
        this.$log.debug(`Clipboard text=${text}`)
        var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
        documentEditor.editor.insertText(text)
      })
      .catch(err => {
        this.$log.debug(`Error copying from clipboard: ${err.message}`)
      })
    },
    handleContextMenu(event) {
      //event.preventDefault()
      //event.stopPropagation()

      try {
        if (event.item) {
          const selection = event.item.properties.text
          this.$log.debug(`ContextMenu selection=${selection}`)
          var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
          // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
          if (navigator.clipboard) {
            if (selection == 'Copy') {
              documentEditor.selection.copy()
            }
            else if (selection == 'Cut') {
              documentEditor.editor.cut()
            }
            else if (selection == 'Font…') {
              documentEditor.showDialog('Font')
            }
            else if (selection == 'Hyperlink…') {
              documentEditor.showDialog('Hyperlink')
            }
            else if (selection == 'Paste Text') {
              this.handlePasteText()
            }
            else if (selection == 'Paste Special') {
              this.handlePasteSpecial()
            }
            else if (selection == 'Paragraph…') {
              documentEditor.showDialog('Paragraph')
            }
          }
          else {
            this.$log.error('Clipboard API not available')
          }
        }
      }
      catch(clipboardErr) {
        this.$log.error(`Unable to handle clipboard request: ${clipboardErr.message}`)
      }
    },
    handleKeyDown(event) {
      // event.event is KeyboardEvent (https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent)
      //
      try {
        let keyboardEvent = event.event
        if (keyboardEvent.altKey || keyboardEvent.ctrlKey || keyboardEvent.metaKey || (keyboardEvent.key == "Shift")) {
          this.$log.debug(`ReportTemplateEditor keyDown event, ignoring key=[${keyboardEvent.key}].`)
          return
        }
        let selection = event.source.selection
        this.$log.debug(`ReportTemplateEditor keyDown event, key=[${keyboardEvent.key}] start=[${selection.startPage}][${selection.startOffset}] end=[${selection.endPage}][${selection.endOffset}]`)
        
        let startOffset1 = selection.startOffset.substring(0, selection.startOffset.lastIndexOf(';'))
        let startOffset2 = selection.startOffset.split(';').pop()
        let endOffset1 = selection.endOffset.substring(0, selection.endOffset.lastIndexOf(';'))
        let endOffset2 = selection.endOffset.split(';').pop()
        if ((selection.startPage == selection.endPage) && (startOffset1 == endOffset1) && (startOffset2 == endOffset2)) {
          if (this.phraseTracker == null) {
            if ((keyboardEvent.key != " ") && (keyboardEvent.key.length == 1)) {
              // Handle checking if existing characters before current cursor location. I.e., user
              // moved cursor within an existing word.
              //
              let newPhrase = true
              let newSentence = false
              const currentStart = selection.startOffset.slice()
              selection.extendForward()
              if ((selection.text.length > 0)) {
                let code = selection.text.charCodeAt(0)
                this.$log.debug(`forward selection.text=[${selection.text}] selection.text[0]=${code}`)
                if ((code != 13) && (code != 32)) {
                  newPhrase = false
                }
              }
              let offset2 = parseInt(startOffset2, 10)
              if (offset2 > 0) {
                this.$log.debug("checking backward startOffset2")
                selection.select(currentStart, currentStart)
                selection.extendBackward()
                if ((selection.text.length > 0)) {
                  let code = selection.text.charCodeAt(0)
                  this.$log.debug(`backward selection.text=[${selection.text}] selection.text[0]=${code}`)
                  if (code != 32) {
                    newPhrase = false
                  }
                  else {
                    // A space preceeds phrase, now check if a completed sentence preceeds.
                    //
                    offset2 = parseInt(selection.endOffset.split(';').pop(), 10)
                    while (offset2 > 0) {
                      selection.extendBackward()
                      let code = selection.text.charCodeAt(0)
                      if (code == 46) {
                        newSentence = true
                        offset2 = 0
                      }
                      else if (code != 32) {
                        offset2 = 0
                      }
                      else {
                        offset2 = selection.endOffset.split(';').pop()
                      }
                    }
                  }
                }
              }
              else {
                newSentence = true
              }
              selection.select(currentStart, currentStart)
              if (newPhrase) {
                this.$log.debug(`ReportTemplateEditor start tracking`)
                this.phraseTracker = {
                  page: selection.startPage,
                  startOffset1: startOffset1,
                  startOffset2: startOffset2,
                  phrase: keyboardEvent.key,
                  newSentence: newSentence
                }
              }
              else {
                this.$log.debug(`ReportTemplateEditor start tracking skipped`)
              }
            }
          }
          else if ((selection.startPage == this.phraseTracker.page) && (startOffset1 == this.phraseTracker.startOffset1) &&
            (parseInt(startOffset2, 10) == (parseInt(this.phraseTracker.startOffset2, 10) + this.phraseTracker.phrase.length)))
          {
            switch(keyboardEvent.key) {
              case " ":
              case ".":
              case ",":
              case ":":
              case ";":
              case "!":
              case "Enter":
              {
                // Handle replacement if needed.
                //
                this.$log.debug(`ReportTemplateEditor end tracking, replace phrase=[${this.phraseTracker.phrase}]`)
                let phraseTracker = { ...this.phraseTracker } // copy before selectionChange event fired from select()
                this.$log.debug(phraseTracker)
                let start = phraseTracker.startOffset1 + ";" + phraseTracker.startOffset2
                let end = phraseTracker.startOffset1 + ";" + (parseInt(this.phraseTracker.startOffset2, 10) + this.phraseTracker.phrase.length).toString()
                if (phraseTracker.phrase in this.$store.state.reportPhraseLut) {
                  this.$log.debug(`ReportTemplateEditor replace start=[${start}] end=[${end}] with [${this.$store.state.reportPhraseLut[phraseTracker.phrase]}]`)
                  event.source.selection.select(start, end)
                  event.source.editor.insertText(this.$store.state.reportPhraseLut[phraseTracker.phrase])
                }
                else if (phraseTracker.newSentence && this.$store.state.reportSettings.capitalize_sentences) {
                  // Auto-correct enabled
                  let phrase = phraseTracker.phrase.charAt(0).toUpperCase() + phraseTracker.phrase.slice(1)
                  this.$log.debug(`ReportTemplateEditor replace start=[${start}] end=[${end}] with [${phrase}]`)
                  event.source.selection.select(start, end)
                  event.source.editor.insertText(phrase)
                }
                this.phraseTracker = null
                break
              }

              case "Backspace":
              {
                const tmpPhrase = this.phraseTracker.phrase.substring(0, this.phraseTracker.phrase.length - 1)
                if (tmpPhrase.length > 0) {
                  this.phraseTracker.phrase = tmpPhrase
                }
                else {
                  this.phraseTracker = null
                }
                break
              }
              
              default:
              {
                if (keyboardEvent.key.length == 1) {
                  this.phraseTracker.phrase += keyboardEvent.key
                  this.$log.debug(`ReportTemplateEditor keep tracking, phrase=[${this.phraseTracker.phrase}]`)
                }
                //else {
                // +TODO+ Just ignore things like End?
                //}
                break
              }
            }
          }
          else {
            this.$log.debug(`ReportTemplateEditor end tracking 1`)
            this.phraseTracker = null
          }
        }
      }
      catch(error) {
        this.$log.warn(`ReportTemplateEditor unable to handle keyDown event: ${error.message}`)
        this.phraseTracker = null
      }
    },
    handleSelectionChange(event) {
      let selection = event.source.selection
      this.$log.debug(`ReportTemplateEditor selectionChange event, start=[${selection.startPage}][${selection.startOffset}] end=[${selection.endPage}][${selection.endOffset}]`)

      // Is this due to the a keyDown event handled already?
      //
      if (this.phraseTracker != null) {
        let startOffset1 = selection.startOffset.substring(0, selection.startOffset.lastIndexOf(';'))
        let startOffset2 = selection.startOffset.split(';').pop()
        let endOffset1 = selection.endOffset.substring(0, selection.endOffset.lastIndexOf(';'))
        let endOffset2 = selection.endOffset.split(';').pop()
        if ((selection.startPage == selection.endPage) && (startOffset1 == endOffset1) && (startOffset2 == endOffset2) &&
            (selection.startPage == this.phraseTracker.page) && (startOffset1 == this.phraseTracker.startOffset1) &&
            (parseInt(startOffset2, 10) == (parseInt(this.phraseTracker.startOffset2, 10) + this.phraseTracker.phrase.length)))
          {
            this.$log.debug(`ReportTemplateEditor selectionChange event, ignoring keyDown pos`)
          }
          else {
            // User moved cursor or some other input changed selection.
            //
            this.phraseTracker = null
          }
      }
    },
    handleDownload() {
      this.displayToast(`Report template download started...`, 'info')
      const format = 'docx'
      var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
      obj.saveAsBlob('Docx')
      .then(docxBlob => {
        let reportName = `${this.templateName}.${format}`
        if (this.templateTenant != this.psuedoTenantForUser) {
          reportName = `${this.templateTenant.replace('/','-')}-${this.templateName}.${format}`
        }
        let reportUrl = URL.createObjectURL(docxBlob)
        this.$log.debug('Direct link to report: ' + reportUrl)
        this.$refs.altReportLauncher.href = reportUrl;
        this.$refs.altReportLauncher.setAttribute("download", reportName)
        this.$refs.altReportLauncher.click()
      })
      .catch(err => {
        this.$log.error(`Unable to download template: ${err.message}`)
        this.displayToast("Report template download failed.", 'danger')
      })
    },
    async handleSave() {
      if (!this.saveInProgress) {
        if (this.templateFiles.length > 1)  {
          //this.$refs.doceditcontainer.ej2Instances.documentEditor.save('header_footer', 'Docx');
          for (let i = 0; i < this.templateFiles.length; i++) {
            document.body.style.cursor = 'wait'
            // Load header footer template from local
            /*
            let templateHeadFoot = "header_footer.docx"
            let ajax = new XMLHttpRequest();
            ajax.open('POST', `${this.serviceUrl}/import`, true);
            ajax.onreadystatechange = () => {
              if (ajax.readyState === 4) {
                if (ajax.status === 200 || ajax.status === 304) {
                  this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.open(ajax.responseText);
                }
              }
            }
            let formData = new FormData();
            formData.append('files', templateHeadFoot);
            ajax.send(formData);
            await webServices.sleep(500);
            */
            
            let file = this.templateFiles[i];
            this.templateCount = i+1;
            try {
              await this.loadFile(file)
              await webServices.sleep(500);
            }
            catch(err) {
              this.$log.error("Error creating template: "+err.message)
              this.warningMsg = 'Report template - ' + this.templateName + ' not saved.'
              this.$refs.warningDialog.show()
              //obj.openBlank();
              document.body.style.cursor = 'default'
            }
            var fpath = file.name.replace(/\\/g, '/');
            var fileName = fpath.substring(fpath.lastIndexOf('/')+1, fpath.lastIndexOf('.'));
            this.templateName = fileName
            var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
            this.saveInProgress = true
            obj.saveAsBlob('Docx')
            .then(docxBlob => {
              if (this.templateTenant == this.psuedoTenantForUser) {
                if (this.reportBuffer === '__NEW_TEMPLATE__') {
                  webServices.createReportTemplateForUser(docxBlob, this.templateName)
                  .then(response => {
                    this.$log.debug(response)
                    this.dirty = false
                    this.templateListUser.push(this.templateName)
                    this.templateListUser.sort();
                  })
                  .catch(err => {
                    this.$log.error("Error creating template: "+err.message)
                    this.warningMsg = 'Report template - ' + this.templateName + ' not saved.'
                    this.$refs.warningDialog.show()
                    obj.openBlank();
                    document.body.style.cursor = 'default'
                  })
                  .finally(() => {
                    this.saveInProgress = false
                  })
                }
              }
              else {
                if (this.reportBuffer === '__NEW_TEMPLATE__') {
                  webServices.createReportTemplateForGroup(docxBlob, `/${this.templateTenant}`, this.templateName, "")
                  .then(response => {
                    this.$log.debug(response)
                    this.dirty = false
                    if (this.templateListTenants[this.templateTenant] === undefined) {
                      this.templateListTenants[this.templateTenant] = []
                    }
                    this.templateListTenants[this.templateTenant].push(this.templateName)
                    this.templateListTenants[this.templateTenant].sort();
                  })
                  .catch(err => {
                    this.$log.error("Error creating template: "+err.message)
                    this.warningMsg = this.warningMsg = 'Report template - ' + this.templateName + ' not saved.'
                    this.$refs.warningDialog.show()
                    obj.openBlank();
                    document.body.style.cursor = 'default'
                  })
                  .finally(() => {
                    this.saveInProgress = false
                  })
                }
              }
            })
            .catch(err => {
                this.$log.error("Error getting report as docx blob: "+err.message)
                this.warningMsg = 'Report not saved.'
                this.$refs.warningDialog.show()
                this.saveInProgress = false
                document.body.style.cursor = 'default'
            })
            //obj.openBlank();
            this.$refs.doceditcontainer.ej2Instances.documentEditor.editorHistory.undo(); 
          }
          document.body.style.cursor = 'default'
          obj.openBlank();
          this.displayToast("Report templates are saved successfully","info")
          this.$log.debug("Report templates are saved successfully.")
        }
        else {
          obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
          this.saveInProgress = true
          obj.saveAsBlob('Docx')
          .then(docxBlob => {
            if (this.templateTenant == this.psuedoTenantForUser) {
              if (this.reportBuffer === '__NEW_TEMPLATE__') {
                webServices.createReportTemplateForUser(docxBlob, this.templateName)
                .then(response => {
                  this.reportBuffer = '__CREATED_TEMPLATE__'
                  this.$log.debug(response)
                  this.dirty = false
                  this.templateListUser.push(this.templateName)
                  this.templateListUser.sort();
                })
                .catch(err => {
                  this.$log.error("Error creating template: "+err.message)
                  this.warningMsg = 'Report template not saved.'
                  this.$refs.warningDialog.show()
                })
                .finally(() => {
                  this.saveInProgress = false
                })
              }
              else {
                webServices.updateReportTemplateForUser(docxBlob, this.templateName)
                .then(response => {
                  this.$log.debug(response)
                  this.dirty = false
                })
                .catch(err => {
                  this.$log.error("Error updating template: "+err.message)
                  this.warningMsg = 'Report template not saved.'
                  this.$refs.warningDialog.show()
                })
                .finally(() => {
                  this.saveInProgress = false
                })
              }
            }
            else {
              if (this.reportBuffer === '__NEW_TEMPLATE__') {
                webServices.createReportTemplateForGroup(docxBlob, `/${this.templateTenant}`, this.templateName, "")
                .then(response => {
                  this.reportBuffer = '__CREATED_TEMPLATE__'
                  this.$log.debug(response)
                  this.dirty = false
                  if (this.templateListTenants[this.templateTenant] === undefined) {
                    this.templateListTenants[this.templateTenant] = []
                  }
                  this.templateListTenants[this.templateTenant].push(this.templateName)
                  this.templateListTenants[this.templateTenant].sort();
                })
                .catch(err => {
                  this.$log.error("Error creating template: "+err.message)
                  this.warningMsg = 'Report template not saved.'
                  this.$refs.warningDialog.show()
                })
                .finally(() => {
                  this.saveInProgress = false
                })
              }
              else {
                webServices.updateReportTemplateForGroup(docxBlob, `/${this.templateTenant}`, this.templateName)
                .then(response => {
                  this.$log.debug(response)
                  this.dirty = false
                })
                .catch(err => {
                  this.$log.error("Error updating template: "+err.message)
                  this.warningMsg = 'Report template not saved.'
                  this.$refs.warningDialog.show()
                })
                .finally(() => {
                  this.saveInProgress = false
                })
              }
            }
          })
          .catch(err => {
              this.$log.error("Error getting report as docx blob: "+err.message)
              this.warningMsg = 'Report not saved.'
              this.$refs.warningDialog.show()
              this.saveInProgress = false
          })
          obj.openBlank();
          this.displayToast("Report templates are saved successfully","info")
          this.$log.debug("Report templates are saved successfully.")
        }
      }
      else {
        this.$log.warn("Request to save report ignored as save already in progress.")
      }
    },
    handleResize(/*event*/) {
      setTimeout(() => {
        try {
          this.editorHeight = "" + (window.innerHeight - 160) + "px"
          this.editorWidth = "" + this.$refs.templateEditorDiv.clientWidth + "px"
          var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
          obj.resize()
          this.editorEnableToolbar = false
          //this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.deleteTable();
          //this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.insertTable(2, 4);
        }
        catch(err) {
          // Most likely component was destroyed before timeout
          this.$log.warn(`handleResize: ${err.message}`)
        }
      }, 1000);
    },
    handleSelectOK() {
      try {
        const templateIdParts = this.templateId.split('|')
        //alert(templateIdParts[0] + " : " + templateIdParts[1])
        this.loadTemplate(templateIdParts[0], templateIdParts[1])
      }
      catch(err) {
        this.$log.error(`Unable to handle template selection: ${err.message}`)
      }
    },
    handleWarningOK() {
      this.saveInProgress = false
      if (this.retryAddTemplate) {
        this.retryAddTemplate = false
        this.$refs['addTemplateDialog'].show()
      }
    }
  }
};

</script>
<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-documenteditor/styles/material.css';
.templateEditorDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.timerDiv {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}
</style>
